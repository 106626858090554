<!-- 变更管理 -->
<template>
  <div class="changeBox">
    <div class="title">
      变更管理-【山东】出租车退役动力电池
    </div>

    <div class="applicationNo">
      <span>拍卖编号</span>
      <span>DCZJP1854467</span>
    </div>

    <div class="change">
      <div class="statusBox">
        <span class="status">即将开始</span>
        <span class="countdown"
          >距开始 01天11时21分31秒 （2023-10-25 10:00开始）</span
        >
      </div>

      <div class="contentBox">
        <!-- 项目日程 -->
        <div class="projectSchedule">
          <div class="title1">项目日程</div>
          <div class="timeLine">
            <time-line-item
              v-for="(item, index) in sourceData"
              :key="'item_' + index"
              :content="item.content"
              :timestamp="item.timestamp"
              :hideTimestamp="false"
              :isActive="item.isActive"
            ></time-line-item>
          </div>
        </div>

        <!-- 运营概况 -->
        <div class="operationalOverview">
          <div class="title1">
            运营概况
          </div>
          <div class="operationalBox">
            <div class="box1">
              <div>
                <span>浏览次数</span>
                <span>75次</span>
              </div>
              <div>
                <span>拍卖用时</span>
                <span>00分00.0秒</span>
              </div>
            </div>
            <div class="box2">
              <div>
                <span>查看人数</span>
                <span>31人</span>
              </div>
              <div>
                <span>出价次数</span>
                <span>0次</span>
              </div>
            </div>
            <div class="box3">
              <div>
                <span>设置提醒</span>
                <span>15人</span>
              </div>
              <div>
                <span>最高出价</span>
                <span>0元</span>
              </div>
            </div>
            <div class="box4">
              <div>
                <span>报名人数</span>
                <span>7人</span>
              </div>
              <div>
                <span>成交价格</span>
                <span>0元</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 项目概况 -->
        <div class="projectOverview">
          <div class="title1">项目概况</div>
          <div class="projectBox">
            <div class="box1">
              <div>
                <span>项目状态</span>
                <span>正常</span>
              </div>

              <div>
                <span>保证金状态</span>
                <span>冻结中</span>
              </div>

              <div>
                <span>合同状态</span>
                <span>--</span>
              </div>
            </div>

            <div class="box2">
              <div>
                <span>综合服务费</span>
                <span>2000元</span>
              </div>

              <div>
                <span>拍卖保证金</span>
                <span>5000元/家（企业）</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btnClass">
        <div class="btn">中止拍卖</div>
        <div class="btn">取消拍卖</div>
      </div>
    </div>
  </div>
</template>

<script>
import timeLineItem from "@/components/timeLine/index.vue";
export default {
  components: {
    timeLineItem,
  },
  data() {
    return {
      sourceData: [
        {
          content: "开始创建",
          timestamp: "2021-11-01",
          isActive: true,
        },
        {
          content: "创建成功",
          timestamp: "2021-11-03",
          isActive: true,
        },
        {
          content: "提交审核",
          timestamp: "2021-11-04",
          isActive: true,
        },
        {
          content: "审核中...",
          timestamp: "2021-11-06",
          isActive: false,
        },
        {
          content: "通过审核",
          timestamp: "2021-11-09",
          isActive: false,
        },
        {
          content: "活动按期开始",
          timestamp: "2021-11-11",
          isActive: false,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.timeLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}
.title1 {
  height: 14px;
  line-height: 14px;
  padding-left: 8px;
  box-sizing: border-box;
  border-left: 4px solid #0dac65;
  font-weight: 500;
  font-size: 16px;
  color: #000000d9;
}
.changeBox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  box-sizing: border-box;
  .title {
    font-weight: 500;
    font-size: 32px;
    color: #252525;
  }
  .applicationNo {
    font-weight: 500;
    font-size: 14px;
    color: #4a4a4a;
    margin-top: 20px;
    span {
      margin-right: 10px;
    }
  }
  .change {
    box-shadow: 0 3px 6px 1px #0000000f;
    padding: 30px 40px;
    box-sizing: border-box;
    margin-top: 40px;
    .statusBox {
      padding-bottom: 42px;
      box-sizing: border-box;
      border-bottom: 1px solid #e0e0e0;
      .status {
        font-weight: 500;
        font-size: 32px;
        color: #0dac65;
        margin-right: 45px;
      }
      .countdown {
        font-weight: 400;
        font-size: 14px;
        color: #00000066;
      }
    }

    .contentBox {
      padding-bottom: 50px;
      padding-top: 50px;
      box-sizing: border-box;
      border-bottom: 1px solid #e0e0e0;
      .projectSchedule {
        .timeLine {
          margin-top: 25px;
        }
      }
      .operationalOverview {
        margin-top: 40px;
        .operationalBox {
          display: flex;
          margin-top: 20px;
          .box1,
          .box2,
          .box3,
          .box4 {
            margin-right: 56px;
            div {
              margin-bottom: 20px;
              span {
                font-size: 14px;
                &:first-child {
                  margin-right: 20px;
                  color: #00000066;
                }
              }
            }
          }
        }
      }

      .projectOverview {
        margin-top: 40px;
        .projectBox {
          .box1,
          .box2 {
            display: flex;
            margin-top: 20px;
            div {
              margin-right: 55px;
              span {
                font-weight: 400;
                font-size: 14px;
                &:first-child {
                  margin-right: 20px;
                  color: #00000066;
                }
              }
            }
          }
        }
      }
    }

    .btnClass {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      .btn {
        width: 120px;
        height: 40px;
        border: 1px solid #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #0dac65;
        text-align: center;
        line-height: 40px;
        margin-left: 20px;
      }
    }
  }
}
</style>
