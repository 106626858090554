<template>
  <div class="item" :style="{ color: isActive ? activeColor : defaultColor }">
    <div class="middle">
      <div
        class="circle"
        :style="{ borderColor: isActive ? activeColor : defaultColor }"
      >
        {{ tampText }}
      </div>
      <div
        class="line"
        :style="{ backgroundColor: isActive ? defaultColor : defaultColor }"
      ></div>
    </div>
    <div class="timestamp" v-if="!hideTimestamp">{{ timestamp }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    // 文字
    content: {
      type: String,
    },
    // 时间节点
    timestamp: {
      type: String,
    },
    // 是否隐藏时间节点
    hideTimestamp: {
      type: Boolean,
      default: false,
    },
    // 节点内文字
    tampText: {
      type: String,
      default: "",
    },
    // 是否为激活状态
    isActive: {
      type: Boolean,
      default: false,
    },
    // 激活状态文字颜色
    activeColor: {
      type: String,
      default: "#0DAC65",
    },
    // 未激活状态文字颜色
    defaultColor: {
      type: String,
      default: "#BFBFBF",
    },
  },
  mounted() {},
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  /* min-width: 100px; */
  width: 150px;
}
.item .content,
.timestamp {
  text-align: left;
  font-size: 14px;
  padding-right: 15px;
}
.item .middle {
  height: 20px;
  display: inline-flex;
  align-items: center;
  padding: 8px 0;
}
.item .middle .circle {
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border: 3px solid #0dac65; /* 边框样式和颜色 */
  line-height: 10px;
  border-radius: 50%;
  color: #fff;
}
</style>

<style lang="less" scoped>
.item {
  .middle {
    .line {
      height: 1px;
      width: calc(100% - 10px);
    }
  }
  &:last-child .middle .line {
    display: none;
  }
}
</style>
